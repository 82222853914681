<template>
  <div class="address-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'address-item',
  data () {
    return {}
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
  .address-item {
    padding: 4px;
    border-bottom: 1px solid #eee;
    &:first-child{
      border-top: 1px solid #eee;
    }
  }
</style>
