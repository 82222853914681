<template>
  <div class="addresses">
    <label>选择地址</label>
    <cell v-for="(item, index) in addresses" :key="index" :isLink="true">
      <div class="title" slot="title">
        <div class="text-small form-group">{{item.firstName + item.lastName}}  {{item.phone}}</div>
        <div class="text-small">{{item.country + item.state}}</div>
      </div>
      <div slot="value"></div>
    </cell>
    <address-item v-for="(item, index) in addresses" :key="index" >
      <div class="title">
        <div class="text-small form-group">{{item.firstName + item.lastName}}  {{item.phone}}</div>
        <div class="text-small">{{item.country + item.state}}</div>
      </div>
    </address-item>
    <div class="sticky-bottom-bar">
      <button class="bottom-item item-block bg-primary">
        <i class="ion-plus-round"></i>
        新建地址
      </button>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import AddressItem from './AddressItem.vue'
export default {
  name: 'addresses',
  components: { AddressItem },
  data () {
    return {
      addresses: []
    }
  },
  created () {
    this.fetchAddresses()
  },
  methods: {
    fetchAddresses () {
      return api.fetchOrderAddresses('delivery').then(data => {
        this.addresses = data
        return data
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">

</style>
